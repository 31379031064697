import React, { useEffect } from "react";
import $ from "jquery";
import Loader from "../components/Loader";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Newsletter from "../components/Newsletter";
import Breadcrumbs from "../components/Breadcrumbs";
import { Link } from "react-router-dom";
/*import GalleryMini from "../components/GalleryMini";*/

export default function Locations({ setHeader }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 1) {
        setHeader(true);
      } else {
        setHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [setHeader]);

  useEffect(() => {
    $(".image-popup").magnificPopup({
      type: "image",
      callbacks: {
        beforeOpen: function () {
          this.st.image.markup = this.st.image.markup.replace(
            "mfp-figure",
            "mfp-figure animated zoomInDown"
          );
        },
      },
      gallery: {
        enabled: true,
      },
    });
  }, []);

  return (
    <>
      <Helmet>
        {/* <!-- meta tag --> */}
        <meta charset="utf-8" />
        <title>
          {t("header.locations.pageTitle")} - DURŌ Niche perfumery - Niche
          parfimerija
        </title>

        {/* <!-- Meta Tags--> */}
        <meta
          name="description"
          content={t("header.locations.metaDescription")}
        />
        <meta
          name="keywords"
          content="durō, duro, niche perfumery, niche parfimerija, beograd, parfimerija, galerija"
        />
        <meta name="author" content="intellect.se" />

        {/* <!-- responsive tag --> */}
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* <!-- Facebook Metadata --> */}
        <meta property="og:image:height" content="1200" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:title" content="DURŌ | Niche perfumery" />
        <meta
          property="og:description"
          content="DURŌ, where the art of niche perfumery meets ultimate luxury. We represent 25 of the most sought-after artisan perfume houses and offer a one-of-a-kind immersive experience in our boutique."
        />
        <meta property="og:url" content="https://www.parfimerija-duro.me" />
        <meta
          property="og:image"
          content="https://www.parfimerija-duro.me/assets/images/favicon/og-image.jpg"
        />
      </Helmet>
      <Loader />
      {/* <!-- Main content Start --> */}
      <div className="main-content">
        {/* <!-- Breadcrumbs Start --> */}
        <div className="rs-breadcrumbs img9">
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">
                {t("header.locations.menuTitle")}
                {/*<span className="watermark">{gallery}</span>*/}
              </h1>
            </div>
          </div>
        </div>
        <Breadcrumbs />
        {/* <!-- Breadcrumbs End --> */}

        {/* <!-- Podgorica Section Start --> */}
        <div className="rs-about style1 lion-bg pt-120 pb-120 md-pt-80 md-pb-75">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 pl-50 md-pl-15">
                <div className="rs-videos choose-video">
                  <div className="images-video">
                    <img
                      src="/assets/images/about/about-12.png"
                      alt="DURŌ Podgorica"
                    />
                  </div>
                  <div className="animate-border">
                    <a
                      className="popup-border"
                      href="https://www.youtube.com/watch?v=I9IFUUBni_E"
                    >
                      <i className="fa fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pl-45 md-pl-15">
                <div className="sec-title">
                  <span className="sub-text">
                    DURŌ {parse(t("locations.podgorica.perfumery"))}
                  </span>
                  <h2 className="title pb-22">
                    {t("locations.podgorica.tagline")}
                  </h2>
                  <div className="heading-border-line left-style"></div>
                </div>
                <p className="desc margin-0 pt-40 pb-15">
                  {parse(t("locations.podgorica.shortStory"))}
                </p>
                <div className="btn-part mt-15">
                  <Link
                    className="readon btn-text ticket"
                    to={`/${i18n.language}/${
                      i18n.language === "en" ? "perfumeries" : "parfimerije"
                    }/podgorica`}
                  >
                    <span>{t("locations.links.learn-more")}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Podgorica Section End --> */}

        {/* <!-- Porto Montenegro Section Start --> */}
        <div className="rs-services style4 lion-bg pt-80 pb-80 md-pt-80 md-pb-80">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 pl-45 md-pl-15">
                <div className="sec-title">
                  <span className="sub-text">
                    DURŌ {parse(t("locations.porto-montenegro.perfumery"))}
                  </span>
                  <h2 className="title pb-22">
                    {t("locations.porto-montenegro.tagline")}
                  </h2>
                  <div className="heading-border-line left-style"></div>
                </div>
                <p className="desc margin-0 pt-40 pb-15">
                  {parse(t("locations.porto-montenegro.shortStory"))}
                </p>
                <div className="btn-part mt-15">
                  <Link
                    className="readon btn-text ticket"
                    to={`/${i18n.language}/${
                      i18n.language === "en" ? "perfumeries" : "parfimerije"
                    }/porto-montenegro`}
                  >
                    <span>{t("locations.links.learn-more")}</span>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 pl-50 md-pl-15">
                <div className="rs-videos choose-video">
                  <div className="images-video">
                    <img src="/assets/images/about/about-13.png" alt="DURŌ" />
                  </div>
                  <div className="animate-border">
                    <a
                      className="popup-border"
                      href="https://www.youtube.com/watch?v=rZhg7Y2CCpY"
                    >
                      <i className="fa fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Porto Montenegro Section End --> */}

        {/* <!-- Newsletter section start --> */}
        <div className="rs-newsletter lion-bg style1 pt-90 md-pt-60 mb--110">
          <Newsletter />
        </div>
        {/* <!-- Newsletter section end --> */}
      </div>
      {/* <!-- Main content End --> */}
    </>
  );
}
