import React, { useEffect } from "react";
import $ from "jquery";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Newsletter from "../components/Newsletter";
import Breadcrumbs from "../components/Breadcrumbs";

export default function Gallery({ setHeader }) {
  const { t } = useTranslation();
  
  const currentPath = window.location.pathname;
  let folderName = "";
  let length = 0;
  if (currentPath.includes("podgorica")) {
    folderName = "podgorica";
    length = 20;
  } else if (currentPath.includes("porto-montenegro")) {
    folderName = "porto-montenegro";
    length = 12;
  }

  const imageSrcList = Array.from({ length: length }, (_, i) => `/assets/images/gallery/${folderName}/${i + 1}.jpg`);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 1) {
        setHeader(true);
      } else {
        setHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [setHeader]);

  useEffect(() => {
    $(".image-popup").magnificPopup({
      type: "image",
      callbacks: {
        beforeOpen: function () {
          this.st.image.markup = this.st.image.markup.replace(
            "mfp-figure",
            "mfp-figure animated zoomInDown"
          );
        },
      },
      gallery: {
        enabled: true,
      },
    });
  }, []);

  return (
    <>
      <Helmet>
        {/* <!-- meta tag --> */}
        <meta charset="utf-8" />
        <title>
          {t("header.gallery.pageTitle")} - DURŌ Niche perfumery - Niche
          parfimerija
        </title>

        {/* <!-- Meta Tags--> */}
        <meta
          name="description"
          content={t("header.gallery.metaDescription")}
        />
        <meta
          name="keywords"
          content="durō, duro, niche perfumery, niche parfimerija, beograd, parfimerija, galerija"
        />
        <meta name="author" content="intellect.se" />

        {/* <!-- responsive tag --> */}
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* <!-- Facebook Metadata --> */}
        <meta property="og:image:height" content="1200" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:title" content="DURŌ | Niche perfumery" />
        <meta
          property="og:description"
          content="DURŌ, where the art of niche perfumery meets ultimate luxury. We represent 25 of the most sought-after artisan perfume houses and offer a one-of-a-kind immersive experience in our boutique."
        />
        <meta property="og:url" content="https://www.parfimerija-duro.me" />
        <meta
          property="og:image"
          content="https://www.parfimerija-duro.me/assets/images/favicon/og-image.jpg"
        />
      </Helmet>
      <Loader />
      {/* <!-- Main content Start --> */}
      <div className="main-content lion-bg">
        {/* <!-- Breadcrumbs Start --> */}
        <div className={`rs-breadcrumbs ${currentPath.includes("podgorica") ? 'img12' : 'img10'}`}>
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">
                {t("gallery")}
                {/*<span className="watermark">{gallery}</span>*/}
              </h1>
            </div>
          </div>
        </div>
        <Breadcrumbs />
        {/* <!-- Breadcrumbs End --> */}

        {/* <!-- Gallery Start --> */}
        <div className="container">
          <div className="row y-middle">
            <div className="rs-gallery style1 mt-60 mb-30">
              <div className="row no-gutters">
                {imageSrcList.map((img) => (
                  <div key={img} className="col-lg-3 col-md-6 col-sm-6">
                    <div className="gallery-item margin">
                      <div className="gallery-icon">
                        <a className="image-popup" href={img}>
                          <img src={img} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Gallery End --> */}

        {/* <!-- Newsletter section start --> */}
        <div className="rs-newsletter lion-bg style1 pt-90 md-pt-60 mb--110">
          <Newsletter />
        </div>
        {/* <!-- Newsletter section end --> */}
      </div>
      {/* <!-- Main content End --> */}
    </>
  );
}
