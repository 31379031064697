export const paths = {
  "our-story": "o-nama",
  "privacy-policy": "politika-privatnosti",
  gallery: "galerija",
  giveaway: "nagradna-igra",
  brands: "brendovi",
  news: "vijesti",
  contact: "kontakt",
  perfumeries: "parfimerije",
};

export const getTranslatedPath = (language) => {
  return Object.keys(paths).reduce((result, key) => {
    result[key] = `/${language}/${language === "en" ? key : paths[key]}`;
    return result;
  }, {});
};
