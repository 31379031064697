import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getTranslatedPath } from "../utils/languageUtils";

export default function Footer2() {
  const { t, i18n } = useTranslation();

  const translatedPaths = getTranslatedPath(i18n.language);

  return (
    <>
      {/* <!-- Footer Start --> */}
      <footer id="rs-footer" className="rs-footer style1">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 md-mb-10">
                <div className="footer-logo mb-40">
                  <Link to={`/${i18n.language}/`}>
                    <img
                      src="/assets/images/logo-footer-light.svg"
                      title="DURŌ"
                      alt="DURŌ"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12 pl-20 md-pl-15">
                    {/*<h3 className="footer-title">{t("footer.footerMain.address")}</h3>*/}
                    <h3 className="footer-title">Podgorica</h3>
                    <div className="textwidget">
                      Bulevar Džordža Vašingtona 68
                      <br />
                      81000 Podgorica
                      <br />
                      <br />
                      <a href="tel:+382 68 002 002">+382 68 002 002</a>
                      <br />
                      {t("footer.footerMain.workHours")} 10.00 - 21.00{" "}
                      {t("footer.footerMain.sunday")}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 md-mb-10 pl-20 md-pl-15">
                    {/*<h3 className="footer-title">{t("footer.footerMain.getInTouch")}</h3>*/}
                    <h3 className="footer-title">Porto Montenegro</h3>
                    <div className="textwidget">
                      Strada Navale, Porto Montenegro
                      <br />
                      85320 Tivat
                      <br />
                      <br />
                      <a href="tel:+382 68 002 002">+382 68 002 002</a>
                      <br />
                      {t("footer.footerMain.workHours")} 09.00 - 23.00{" "}
                      {t("footer.footerMain.sunday")}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 pl-115 md-pl-15">
                    <h3 className="footer-title">
                      {t("footer.footerMain.follow")}
                    </h3>
                    <ul className="footer-social">
                      <li>
                        <a href="https://www.facebook.com/duro.montenegro">
                          <i className="fa-brands fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.twitter.com/DuroCologne">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/duro_montenegro">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/@duro_montenegro">
                          <i className="fa-brands fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.tiktok.com/@duro_montenegro">
                          <i className="fa-brands fa-tiktok"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 md-mb-10 text-lg-end text-center order-last">
                <ul className="copy-right-menu">
                  <li>
                    <Link to={`/${i18n.language}/`}>
                      {t("footer.footerMain.home")}
                    </Link>
                  </li>
                  <li>
                    <Link to={translatedPaths["privacy-policy"]}>
                      {t("footer.footerMain.privacyPolicy")}
                    </Link>
                  </li>
                  <li>
                    <Link to={translatedPaths["contact"]}>
                      {t("footer.footerMain.contact")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="copyright text-lg-start text-center">
                  <p>
                    {t("footer.footerHome.heartBefore")}{" "}
                    <i className="fa fa-heart"></i>{" "}
                    {t("footer.footerHome.heartAfter")}
                    <a href="https://intellect.se">Intellect</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- Footer End --> */}
    </>
  );
}
